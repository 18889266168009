import { template as template_3b27d4f17e0e4a7d9ab115f10577025c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3b27d4f17e0e4a7d9ab115f10577025c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
