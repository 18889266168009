import { template as template_dfdb297a5e4b4aad82f37b9b70f4e5ba } from "@ember/template-compiler";
const FKText = template_dfdb297a5e4b4aad82f37b9b70f4e5ba(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
